import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';
import img4 from '../images/img4.png';
import img5 from '../images/img5.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';
import img10 from '../images/img10.png';
import img11 from '../images/img11.png';
import img12 from '../images/img12.png';
import img13 from '../images/img13.png';
import img14 from '../images/img14.png';

export const SliderProducts = [
  {
    name: 'SKIN',
    detail: 'Super Skin Care',
    price: '25',
    img: img1,
  },
  {
    name: 'SKIN',
    detail: 'Super Skin Care',
    price: '30',
    img: img2,
  },
  {
    name: 'NATURE',
    detail: 'Super Skin Care',
    price: '25',
    img: img3,
  },
];
export const ProductsData = [
  {
    name: 'SKIN',
    detail: 'Super Skin Care',
    price: '25',
    img: img1,
    type: 'skin care',
  },
  {
    name: 'SKIN',
    detail: 'Super Skin Care',
    price: '30',
    img: img2,
    type: 'skin care',
  },
  {
    name: 'NATURE',
    detail: 'Super Skin Care',
    price: '25',
    img: img3,
    type: 'skin care',
  },
  {
    name: 'Foundation',
    detail: 'Super Skin Care',
    price: '25',
    img: img4,
    type: 'foundation',
  },
  {
    name: 'CONDITIONER',
    detail: 'Super Skin Care',
    price: '30',
    img: img5,
    type: 'conditioner',
  },
  {
    name: 'NATURE',
    detail: 'Super Skin Care',
    price: '25',
    img: img6,
    type: 'skin care',
  },
  {
    name: 'CONDITIONER',
    details: 'Best Conditioner',
    price: '30',
    img: img7,
    type: 'conditioner',
  },
  {
    name: 'CONDITIONER',
    details: 'Best Conditioner',
    price: '30',
    img: img8,
    type: 'conditioner',
  },
  {
    name: 'CONDITIONER',
    details: 'Best Conditioner',
    price: '30',
    img: img9,
    type: 'conditioner',
  },
  {
    name: 'CONDITIONER',
    details: 'Best Conditioner',
    price: '30',
    img: img10,
    type: 'conditioner',
  },
  {
    name: 'FOUNDATION',
    details: 'Nourish your skin',
    price: '12',
    img: img11,
    type: 'foundation',
  },
  {
    name: 'FOUNDATION',
    details: 'Nourish your skin',
    price: '12',
    img: img12,
    type: 'foundation',
  },
  {
    name: 'FOUNDATION',
    details: 'Nourish your skin',
    price: '12',
    img: img13,
    type: 'foundation',
  },
  {
    name: 'FOUNDATION',
    details: 'Nourish your skin',
    price: '12',
    img: img14,
    type: 'foundation',
  },
];
