import profile1 from '../images/profile1.png';
import profile2 from '../images/profile2.png';

export const TestimonialsData = [
  {
    image: profile1,
    comment:
      ' Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    name: 'John Alendon',
  },
  {
    image: profile2,
    comment:
      ' Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    name: 'Natasha',
  },
  {
    image: profile1,
    comment:
      ' Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    name: 'Alex Hormonzi',
  },
  {
    image: profile1,
    comment:
      ' Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    name: 'John Alendon',
  },
  {
    image: profile2,
    comment:
      ' Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    name: 'Natasha',
  },
  {
    image: profile1,
    comment:
      ' Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    name: 'Alex Hormonzi',
  },
];
